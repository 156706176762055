.accounts-container {
  height: 100%;
  position: relative;
}

.accounts-paginator {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.accounts {
  svg {
    path {
      fill: #1f80b4;
    }

    circle {
      fill: #8fbfda;
    }
  }
  .table-header-filter {
    margin-top: 0;
    padding-top: 0;
    border-top: none;
  }
}
