$side-panel-width: 300px;

.container {
  display: flex;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

#mainContent {
  flex: 1;
  padding: 15px 30px 15px;
  max-width: 100%;
}

#sideDetails {
  width: 0;
  box-shadow: 0px 4px 40px rgba(167, 167, 167, 0.25);
  transition: width 0.2s;
  z-index: 5;

  &.open {
    width: $side-panel-width;

    @at-root #mainContent {
      width: calc(100% - #{$side-panel-width});
    }
  }

  .content {
    height: 100%;
    padding: 30px;
    background: #fff;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
  border-bottom: 1px solid #e8e8e8;

  .headerTitle {
    margin-bottom: 0;
    font-weight: bold;
  }

  .closeButton {
    background: none;
    border: none;
    outline: none;
  }
}

.block {
  margin-top: 30px;

  .heading {
    font-weight: bold;
    color: #1f80b4;
    margin-bottom: 10px;
  }

  .item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .value {
    color: #000;
  }
}
