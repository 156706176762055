.current-time {
  position: absolute;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
  padding: 3px 7px;
  border: 1px solid #1f80b4;
  border-radius: 3px;
  color: #1f80b4;
  font-size: 12px;
  font-weight: bold;
}

.time-box {
  position: sticky;
  width: 100%;
  z-index: 3;
  top: 0;
  margin-left: 0;
  height: 0;
  .airplane-detail-table-content-row {
    background: initial;
  }
  .time-line-box {
    position: relative;
    width: 100%;
  }
  .time-line {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 2px;
    background: #1f80b4;
    height: 741px;
    top: 48px;
    &-arrow-top {
      position: absolute;
      top: 3px;
      fill: #1f80b4;
    }
    &-arrow-top.step-in-minutes {
      top: -3px;
    }
    &-arrow-bottom {
      position: absolute;
      bottom: 0;
      fill: #1f80b4;
    }
  }
}
