.airports-labels {
  min-width: 500px;
  margin: 0;
  padding: 0;
  list-style: none;
  flex-basis: 50%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  &__item {
    flex-basis: 15%;

    &.name {
      flex-basis: 40%;
    }
  }

  &__title {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #A8A8A8;
  }

  &__icon {
    margin-right: 10px;
    object-fit: cover;
    object-position: center;
    border-radius: 3px;
  }

  &__value {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }
}