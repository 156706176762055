.requestInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px 11px;
  border-bottom: 1px solid #e8e8e8;
}

.endpoint {
  padding-left: 10px;
}

.apiKeyContainer {
  display: flex;
  align-items: flex-end;
  padding: 15px;
  padding-bottom: 30px;
}

.apiKey {
  display: flex;
  align-items: flex-end;
  max-width: 60%;
  margin-right: 50px;
}

.copyIcon {
  min-width: 18px;
  margin-bottom: 10px;
  margin-left: 10px;
  cursor: pointer;

  path {
    fill: #1f80b4;
  }
}

.parametersWrap {
  margin-bottom: 20px;
}

.parametersHeader {
  padding: 10px;
  margin-left: 0;
  background: #f0f0f0;
  border-bottom: 1px solid #e8e8e8;
  border-top: 1px solid #e8e8e8;
  font-size: 14px;
}

.parametersContainer {
  padding: 20px 15px 10px;
}

.parameter {
  margin-bottom: 20px;
}
