.my-input {
  display: flex;
  align-items: stretch;
  flex-direction: column;

  &-inner {
    margin-bottom: 0;

    &__label {
      display: inline-block;
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 16px;
      color: #a8a8a8;
    }

    &__input {
      width: 100%;
      padding: 9px 15px;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
      outline: none;
      background: transparent;
      border: 1px solid #e0e0e0;
      box-sizing: border-box;
      border-radius: 6px;

      &:focus {
        border: 1px solid #1f80b4;
      }
    }
  }
}
