.container {
  height: 100%;
  position: relative;
}

.paginator {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX((-50%));
}
