.container {
  padding: 40px 0 24px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.paginator {
  display: flex;
  align-items: center;

  & > * {
    margin-right: 10px;
    background: #8fbfda;
    transition: all 0.4s;

    &:hover {
      background: #1f80b4;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .my-btn {
    min-width: 130px;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  outline: none;

  &:focus {
    outline: none;
  }
}

.current {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  font-weight: 900;
  font-size: 18px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  outline: none;
}
