.check-wl {
  position: relative;
  display: flex;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__input {
    position: absolute;
    outline: none;
    appearance: none;
    opacity: 0;
    box-shadow: none !important;
  }

  &__label {
    padding: 0 25px;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #9f9f9f;
    cursor: pointer;
  }

  &__label:before {
    content: url('./check.svg');
    display: block;
    width: 18px;
    height: 18px;

    position: absolute;
    top: 2px;
    left: 0;
    z-index: 1;

    transition: background 0.1s linear, border 0.1s linear;
  }

  &__label:after {
    content: url('./check-active.svg');
    display: block;
    width: 18px;
    height: 18px;
    opacity: 0;

    position: absolute;
    top: 2px;
    left: 0;
    z-index: 2;

    transition: opacity 0.1s linear;
  }

  .check-wl__input:checked + .check-wl__label {
    color: #000000;
  }

  .check-wl__input:checked + .check-wl__label:after {
    opacity: 1;
  }
}
