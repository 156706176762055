@import '~theme/theme';

.airport {
  .admin-label-group {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &-buttons {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      &-activate {
        background: none;
        border: none;
        outline: none;
        margin-right: 20px;
        color: $gray-text;

        .text {
          margin-right: 10px;
        }

        .activated {
          path {
            fill: $green;
          }
        }

        svg {
          path {
            fill: $gray-text;
            transition: fill 0.3s ease;
          }
        }
      }
    }
  }

  .btn {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    &_brd {
      border: 1px solid #1f80b4;
      box-sizing: border-box;
      border-radius: 6px;

      &:focus {
        box-shadow: none;
      }
    }

    &_icon {
      padding: 2px 15px 2px 10px;
    }

    &__text {
      margin: 0 10px;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #1f80b4;
    }

    &-action-group {
      position: relative;
    }

    &-context {
      position: absolute;
      top: calc(100% + 10px);
      right: 0;
      display: flex;
      flex-direction: column;
      min-width: 300px;
      z-index: 999;
    }
  }

  .btn-action {
    width: 100%;
    padding: 20px;
    background: #ffffff;
    border: 1px solid #8fbfda;
    box-sizing: border-box;
    border-radius: 6px;

    &__search {
      margin-bottom: 20px;
    }

    &__list {
      width: 100%;
      height: 300px;
      margin: 0;
      padding: 0;
      padding-right: 8px;
      overflow-y: auto;
      list-style: none;

      // &::-webkit-scrollbar {
      //   width: 7px;
      //   border-radius: 3px;
      // }

      // &::-webkit-scrollbar-thumb {
      //   background-color: darkgrey;
      //   outline: 1px solid slategrey;
      //   border-radius: 3px;
      // }
    }

    &__item {
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      outline: none;

      &:last-child {
        margin-bottom: 0;
      }

      &-label,
      &-country {
        font-size: 16px;
        line-height: 19px;
        color: #1f80b4;
      }

      &-label {
        margin-right: 15px;
      }

      &-country {
        text-align: right;
      }

      &_active {
        font-weight: bold;
      }
    }
  }
}

.import {
  margin-right: 15px;
}
