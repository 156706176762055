@import '~theme/theme';

#modal-container {
  position: fixed;
  display: flex;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  transform: scale(0);
  z-index: 10;

  &.modal-open {
    transform: scale(1);

    .modal-background {
      background: rgba(0, 0, 0, 0);
      animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

      .modal-inner {
        opacity: 0;
        animation: scaleUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
      }
    }

    &.out {
      animation: quickScaleDown 0s 0.5s linear forwards;

      .modal-background {
        animation: fadeOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

        .modal-inner {
          animation: scaleDown 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
        }
      }
    }
  }

  .modal-background {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.8);
    outline: none;
    cursor: default;

    .modal-inner {
      min-width: 20%;
      background: white;
      padding: 15px;
      border-radius: 6px;
      position: relative;
    }
  }
}

@keyframes fadeIn {
  0% {
    background: rgba(0, 0, 0, 0);
  }

  100% {
    background: rgba(0, 0, 0, 0.7);
  }
}

@keyframes fadeOut {
  0% {
    background: rgba(0, 0, 0, 0.7);
  }

  100% {
    background: rgba(0, 0, 0, 0);
  }
}

@keyframes scaleUp {
  0% {
    transform: scale(0.8) translateY(1000px);
    opacity: 0;
  }

  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
}

@keyframes scaleDown {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }

  100% {
    transform: scale(0.8) translateY(1000px);
    opacity: 0;
  }
}

@keyframes quickScaleDown {
  0% {
    transform: scale(1);
  }

  99.9% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}
