.container {
}

.block {
  max-width: 300px;
  margin-bottom: 30px;
}

.blockHeading {
  margin-bottom: 5px;
}

.input {
  margin-bottom: 10px;
}
