.action {
  width: 100%;
  display: flex;
  align-items: center;
}

.flights-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.datepicker {
  display: flex;
  align-items: center;
  border-radius: 6px;
  margin-right: 20px;

  &__label {
    margin-right: 5px;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #a8a8a8;
  }

  .react-date-picker__wrapper {
    border: none;
    font-weight: 500;
    font-size: 16px;
  }

  .react-date-picker__calendar {
    z-index: 101;
  }

  .react-date-picker__inputGroup {
    text-align: center !important;
    padding: 0 !important;
    input {
      color: #1f80b4
    }
  }

  .react-date-picker__clear-button,
  .react-date-picker__calendar-button {
    outline: none;
    padding: 0 !important;
    margin: 4px 6px !important;

    svg {
      stroke: #1f80b4;
    }

    &:hover {
      transform: scale(1.2) !important;
      transition: all 0.4s !important;
    }
  }

  .react-calendar {
    padding: 15px;
    background: #ffffff;
    border: 1px solid #8fbfda;
    box-sizing: border-box;
    border-radius: 6px;

    abbr {
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #1f80b4;
    }

    .react-calendar__tile--now {
      border-radius: 4px;
      background: #e8e8e8;
    }

    .react-calendar__tile--active {
      background: #1f80b4;
      border-radius: 4px;

      abbr {
        color: #ffffff;
      }
    }

    .react-calendar__tile--rangestart {
      border-radius: 4px 0 0 4px;
    }

    .react-calendar__tile--hasactive,
    .react-calendar__tile--rangestart,
    .react-calendar__tile--rangeend {
      background: #1f80b4;
      border-radius: 4px;

      abbr {
        color: #ffffff;
      }
    }

    .react-calendar__tile--rangestart {
      border-radius: 4px 0 0 4px;
    }

    .react-calendar__tile--rangeend {
      border-radius: 0 4px 4px 0;
    }
  }
}

.button-group {
  display: flex;
  align-items: center;

  & > * {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  .table-action {
    &-btn {
      padding: 12px 30px;
      min-width: 130px;
      width: 100%;
      display: flex;
      align-items: center;
      background: #1f80b4;
      border-radius: 6px;
      cursor: pointer;
      transition: all 0.3s;

      &__img {
        margin-right: 8px;

        path {
          fill: #ffffff;
        }
      }

      &__text {
        width: 100%;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        color: #ffffff;
      }

      &:hover {
        background: #0d5378;
      }

      // --bordered
      &--bordered {
        padding: 11px 29px;
        background: transparent;
        border: 1px solid #1f80b4;
        box-sizing: border-box;
        border-radius: 6px;

        .table-action-btn__text {
          color: #1f80b4;
        }

        .table-action-btn__img path {
          fill: #1f80b4;
        }

        &:hover {
          background: #0d5378;
          border: 1px solid #0d5378;

          svg path {
            fill: #ffffff;
          }

          .table-action-btn__text {
            color: #ffffff;
          }
        }
      }
    }
  }
}
