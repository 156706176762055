.container {
  margin-top: 40px;
}

.header {
  flex: 1;
  color: #383838;
}

.content {
  padding: 30px;
  border-radius: 0 0 6px 6px;
  background: #fff;
}

.tabList {
  border-bottom: 1px solid #1f80b4;
  margin: 0;
  padding: 0;
}

.tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 10px 20px;
  cursor: pointer;
}

.selectedTab {
  background: #1f80b4;
  color: #fff;
  border-radius: 5px 5px 0 0;
}
