.sidebar {
  max-width: 120px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #1f80b4;

  .bento-menu-container {
    width: 36px;
    height: 36px;
    margin: 0 auto;
    margin-top: 30px;
    fill: #fff;
    cursor: pointer;
    position: relative;
  }

  &-menu {
    padding: 56px 30px 30px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  &-add-account {
    width: 100%;
    padding: 10px;
    margin-bottom: 40px;
    border-top: 1px solid #8fbfda;
    border-bottom: 1px solid #8fbfda;
    text-align: center;
    cursor: pointer;

    &:hover {
      .icon {
        fill: #fff;
      }
    }

    .icon {
      transition: fill 0.2s ease;
      fill: #8fbfda;
    }
  }

  &-list {
    margin: 0;
    padding: 0;
    height: 100%;
    list-style: none;

    &-item {
      margin-bottom: 22px;
      text-align: center;

      a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: all 0.3s;

        &:hover {
          text-decoration: none;

          .sidebar-list-item__label {
            text-decoration: none;
            color: #ffffff;
          }

          .sidebar-list-item__img {
            path {
              fill: #ffffff;
            }
          }
        }
      }

      &__img {
        path {
          fill: #8fbfda;
        }
      }

      &--active {
        .sidebar-list-item__label {
          color: #ffffff;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      &__img {
        margin: 0 0 13px 0;
      }

      &__label {
        display: block;
        margin-bottom: 0;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: #8fbfda;
        text-decoration: none;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  &-user-box {
    position: relative;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 40px;

    &-img__border {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      border: 2px solid #8fbfda;
      color: #fff;
      border-radius: 50%;
      padding: 2px;
      overflow: hidden;
      cursor: pointer;
    }

    img {
      width: 100%;
      max-width: 56px;
      height: auto;
    }
  }

  .popup {
    position: absolute;
    left: calc(100% + 10px);
    bottom: 0;
    min-width: 200px;
    padding: 5px 0;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
      rgba(9, 30, 66, 0.31) 0px 0px 1px;
    border-radius: 4px;
    background: #fff;
    z-index: 999;

    & .item {
      width: 100%;
      padding: 8px 12px;

      .icon {
        margin-right: 10px;
        fill: #1f80b4;
      }
    }

    & .action {
      color: #000;
      background: none;
      border: none;
      text-align: left;
      outline: none;
      cursor: pointer;

      a {
        color: #000;
      }

      &:hover {
        background: #f2f2f2;
      }

      &.active {
        background: #f2f2f2;
      }
    }
  }

  .menu-popup {
    top: 100%;
    left: 0;
    bottom: auto;
  }
}
