.container {
  position: relative;
  width: 100%;
  margin-top: 70px;
}

.defaultLine {
  position: absolute;
  width: 100%;
  height: 5px;
  background: #c5c5c5;
}

.categoryCircleMessage {
  visibility: hidden;
}

.categoryCircle {
  position: absolute;
  top: -8px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid #c5c5c5;
  background: #fff;
  transition: all 0.2s ease;
  cursor: pointer;
  z-index: 2;

  &.default {
    background: #c5c5c5;
  }

  &.yellow {
    background: #e8bc5c;
  }
  &.green {
    background: #40a857;
  }
  &.red {
    background: #e85c5c;
  }

  &:hover {
    .categoryCircleMessage {
      padding: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      visibility: visible;
      top: 30px;
      left: -150px;
      width: 150px;
      height: 30px;
      background-color: #ffffff;
      border: none;
      border-radius: 5px;
      box-shadow: 0 0 14px 0 #797777;
    }
  }

  &:last-child:hover {
    .categoryCircleMessage{
      left: 200px;
    }
  }
}

.signCategoryCircleMessage {
  margin-left: 10px;
  font-size: 1.4rem;
  font-weight: 600;
  color: #F56A5D;
}
.TextCategoryCircleMessage {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  color: #000;
  font-weight: 500;
}

.delayBox {
  display: flex;
  align-items: flex-start;
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  width: 180px;
  transform: translateX(-50%);
  padding: 10px 15px;
  background: #fff;
  border-radius: 3px;
  box-shadow: rgba(232, 92, 91, 0.25) 0 4px 8px -2px, rgba(9, 30, 66, 0.31) 0px 0px 1px;
  color: #303030;
  font-size: 14px;
}

.delayMessage {
  margin-left: 7px;
}

.done {
  background: #40a857;
  border: none;
}

.categoryName {
  position: absolute;
  transform: translateX(-50%);
  bottom: 7px;
  max-width: 100px;
  text-align: center;
  font-size: 15px;
  color: #303030;
}

.progress {
  position: absolute;
  height: 5px;
  background: #40a857;

  &.default {
    background: #9f9f9f;
  }
  &.yellow {
    background: #e8bc5c;
  }
  &.green {
    background: #40a857;
  }
  &.red {
    background: #e85c5c;
  }
}

.delayCircle {
  border: 5px solid rgb(232, 92, 92);
}

.delayLine {
  background: rgb(232, 92, 92);
}

.delayCircle.categoryCircle {
  animation: pulse 2s infinite;

  &:hover {
    animation: none;
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(232, 92, 92, 0.4);
    box-shadow: 0 0 0 0 rgba(232, 92, 92, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(232, 92, 92, 0);
    box-shadow: 0 0 0 10px rgba(232, 92, 92, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(232, 92, 92, 0);
    box-shadow: 0 0 0 0 rgba(232, 92, 92, 0);
  }
}

.test_denys:last-child {
  background-color: #0d5378;
  height: 40px;
  //&:hover .categoryCircle .categoryCircleMessage {
  //  left: -250px;
  //}
}

