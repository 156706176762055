.modal {
  position: relative;
  width: 70%;
  //height: 80%;
}

.imageContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  img {
    max-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }
}

.closeButton {
  position: absolute;
  right: 10px;
  cursor: pointer;
  top: 10px;
  background: transparent;
  border: none;
  font-size: 2rem;
  font-weight: 600;
  &:hover {
    transform: scale(1.1);
    transition: all 0.4s linear;
  }
}

.imgInformation {
  position: absolute;
  left: 45%;
  transform: rotateX(-50%);
  top: 5px;
  text-align: center;
  h4 {
    font-size: 1rem;
    color: #000000;
    font-weight: 600;
  }
}
