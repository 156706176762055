.airplane-detail-table {
  &-content {
    overflow: auto;
    height: 100%;
    padding-right: 10px;

    &-row {
      display: flex;
      background: #fff;
      margin: 0 0 4px 0;
      color: #000;
      border-radius: 6px;
      max-height: 48px;
      &:last-child {
        margin: 0;
      }
      .airplane-detail-table-header-timeline {
        padding: 0;
        max-width: 451px;
        //overflow: hidden; // test style

        .inner {
          width: 100%;

          @media screen and (min-width: 1441px) {
            width: calc(100% - 62px);
          }
        }

        @media screen and (min-width: 1441px) {
          max-width: initial;
        }
      }
      .timeline-box {
        width: 451px;
        position: relative;
        z-index: 2;
        overflow: hidden; // test
        @media screen and (min-width: 1441px) {
          width: calc(100% - 62px);
        }
      }
      .timeline-planned {
        height: 100%;
        display: flex;
        align-items: center;

        &-progress {
          position: absolute;
          height: 23px;
          background: inherit;
          left: 0;
          border-radius: 3px;
        }

        &-progress.green {
          background: #54d771;
        }

        &-progress.red {
          background: #e85c5c;
        }

        &-progress.yellow {
          background: #e8bc5c;
        }
      }
    }
    .detail-item {
      padding: 0 0 0 40px;
      .airplane-detail-table-header-item {
        &:first-child {
          flex-basis: 268px;
        }
        &:nth-child(3) {
          flex-basis: 87px;
        }
      }
      .airplane-detail-table-content-row {
        color: #a8a8a8;
        background: #f9faff;
        border-bottom: 1px solid #e8e8e8;
        border-radius: 0;
        &:last-child {
          border-bottom: none;
        }
      }
      .airplane-detail-table-header-timeline {
        align-items: center;
      }
      .time-circle {
        position: relative;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        z-index: 2;
      }

      .event-timestamp {
        position: absolute;
        left: calc(100% + 5px);
        top: 50%;
        transform: translateY(-50%);
        font-size: 12px;
        color: #303030;

        .blue {
          color: #1f80b4;
        }

        .green {
          color: #54d771;
        }

        .red {
          color: #e85c5c;
        }

        .black {
          color: #000;
        }
      }

      .time-circle.blue {
        background: #1f80b4;
      }
      .time-circle.green {
        background: #54d771;
      }
      .time-circle.red {
        background: #e85c5c;
      }
      .time-circle.black {
        background: #000;
      }
    }
    .airplane-detail-table-header-item.details-box {
      flex-basis: 44px;
      .box {
        width: 24px;
        height: 24px;
        margin-right: 10px;
        background: #f0f2f8;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s;
        &:hover {
          background: #1f80b4;
          .airplane-detail-content-icon_color {
            fill: #f0f2f8;
          }
        }
      }
    }
    .attention-box {
      background: #e85c5c;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 40%;
      position: absolute;
      right: 24px;
      transition: all 0.3s;
      .svg-icon {
        position: relative;
        left: 1px;
      }
      &:hover {
        .attention-box-hint {
          display: flex;
        }
      }
      &-hint {
        background: inherit;
        display: none;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 6px;
        min-width: 140px;
        min-height: 50px;
        position: absolute;
        right: 39px;
        &:before {
          content: ' ';
          background: inherit;
          transform: rotate(-135deg);
          width: 10px;
          height: 10px;
          position: absolute;
          right: -5px;
        }
      }
    }
    .airplane-detail-content-icon_color {
      fill: #1f80b4;
    }
  }
}
