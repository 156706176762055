.container {
  position: relative;
}

.header {
  display: flex;
  align-items: center;
  min-height: 40px;
  margin-bottom: 15px;
}

.finishButton {
  margin-left: 15px;
}

.gateSelect {
  position: absolute;
}
