.container {
  position: relative;
  width: 100%;
  display: flex;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.input {
  position: absolute;
  outline: none;
  appearance: none;
  opacity: 0;
  box-shadow: none !important;
}

.label {
  padding: 3px 25px 0;
  margin-bottom: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #303030;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:before {
    content: url('./radio.svg');
    display: block;
    width: 18px;
    height: 18px;

    position: absolute;
    top: 5px;
    left: 0;
    z-index: 1;

    transition: background 0.1s linear, border 0.1s linear;
  }

  &:after {
    content: url('./radio-active.svg');
    display: block;
    width: 18px;
    height: 18px;
    opacity: 0;

    position: absolute;
    top: 5px;
    left: 0;
    z-index: 2;

    transition: opacity 0.1s linear;
  }
}

.input:checked + .label {
  color: #1f80b4;
}

.input:checked + .label:after {
  opacity: 1;
}
