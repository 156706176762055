@import '~theme/theme';

.dropdown {
	display: flex;
	align-items: center;
	padding: 10px 0;
	width: 100%;
	border-bottom: 1px solid #e8e8e8;
	cursor: pointer;

	&-arrow {
		transition: all 0.3s ease;

		&__active {
			transform: rotate(90deg);
		}
	}

	&-title {
		width: 100%;
		display: flex;
		align-items: center;
	}
}

.dropdown:focus {
	outline: none;
}

.dropdown-icon {
	min-width: 23px;
	margin-right: 20px;
}
