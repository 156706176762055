.current-processes {
  display: flex;
  margin-top: 30px;
}

.timeline-arrow {
  transform: rotate(90deg);
  transform-origin: center center;
  transition: transform 0.2s ease;
  fill: #fff;

  &_open {
    transform: rotate(-90deg);
  }
}

.top-panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.general-flight-details {
  display: flex;
  align-items: center;
  width: 85%;
}

.turnaround-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 180px;
  cursor: pointer;
  color: #1f80b4;

  &:focus {
    outline: none;
  }

  .title {
    font-weight: bold;
    margin-bottom: 0;
  }
}

.turnaroundMode {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  &:focus {
    outline: none !important;
  }
}

.mode {
  padding-left: 12px;
  margin-bottom: 5px;
  color: #fff;
  text-transform: capitalize;
}

.modeLabel {
  padding-right: 3px;
  color: rgba(255, 255, 255, 0.6);
  font-weight: bold;
}

.modeDetailsPopup {
  position: absolute;
  right: calc(100% + 10px);
  top: 10px;
  min-width: 220px;
  padding: 7px 0;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.31) 0px 0px 1px;
  border-radius: 4px;
  background: #1f80b4;
  z-index: 999;

  .camerasPopup {
    position: absolute;
    right: calc(100% + 10px);
    top: 30px;
    min-width: 180px;
  }

  .item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 4px 12px;
    color: #fff;
    text-transform: capitalize;
  }

  .camera-name {
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 15px;
    text-transform: initial;
  }

  .title {
    color: rgba(255, 255, 255, 0.6);
    font-weight: bold;
  }

  & .action {
    cursor: pointer;
    background: #1f80b4;
    transition: all 0.3s ease;

    &:hover {
      background: darken(#1f80b4, 5%);
    }
  }
}

.flight-tracker {
  //height: 100%;
  display: grid;
  grid-template-rows: 75px auto minmax(300px, auto);
  grid-template-columns: 100%;

  .images-container {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }

  .live-container {
    min-width: 300px;
    max-width: 300px;
    padding-left: 40px;
    margin-left: 40px;
    border-left: 1px solid #e8e8e8;
  }

  .live-image {
    width: 100%;
    max-height: 150px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    cursor: pointer;

    img {
      max-width: 100%;
      min-height: 100%;
      object-fit: cover;
    }
  }

  &-header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  .header {
    padding-right: 43px;
    border-right: 1px solid rgba(167, 169, 175, 0.4);
    max-height: 74px;
  }
  .airplane-detail {
    position: initial;
    background: inherit;
    box-shadow: none;
    z-index: 1;
    .main.airplane {
      padding: 0;
    }

    .table-content {
      margin: 0;
    }
    &-header {
      padding: 0;
      align-items: center;
      border: none;
      justify-content: space-between;
      margin: 0;
      .search-box {
        margin: 0 0 0 24px;
      }
      &__column {
        @media screen and (min-width: 1800px) {
          flex-direction: column;
          align-items: initial;
          position: initial;
        }
        &:last-child {
          margin: 0;
        }
      }
      &__title {
        @media screen and (min-width: 1800px) {
          margin: 0 0 5px 0;
        }
      }
    }
    &-content {
      position: absolute;
      width: calc(100% + 208px);
      left: -208px;
      margin: 30px 0 0;
    }
  }
  .info-toggle {
    display: flex;
    align-items: center;
    height: 24px;
    outline: none;

    &-title {
      color: #1f80b4;
    }

    &-line.active {
      margin: 0;
    }

    &-line {
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;
      height: 1px;
      background: #e8e8e8;
      border-radius: 3px;
      margin: 0 0 0 30px;
      .arrow-box {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0;
        background: #1f80b4;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        &:hover {
          .arrow-box-hint {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          background: #0d5378;
        }
        .arrow {
          fill: #fff;
          position: relative;
        }
        .arrow-down {
          top: 1px;
        }
        .arrow-top {
          top: -1px;
        }
        &-hint {
          display: none;
          position: absolute;
          right: 40px;
          min-width: 130px;
          min-height: 50px;
          color: #fff;
          background: #1f80b4;
          box-shadow: 0px -10px 40px rgba(30, 35, 41, 0.06);
          border-radius: 6px;
          z-index: 5;
          &:before {
            content: ' ';
            position: absolute;
            top: 21px;
            right: -5px;
            width: 10px;
            height: 10px;
            background: #1f80b4;
            border-radius: 1px;
            transform: rotate(-135deg);
          }
        }
      }
    }
  }
}
