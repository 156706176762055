.container {
  min-width: 270px;
  background: #f9faff;
  border: 1px solid #8fbfda;
  box-sizing: border-box;
  border-radius: 6px;
  flex-basis: 24%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 10px;
  outline: none;

  &__add {
    margin: 0;
    padding: 10px;
    max-width: 115px;
    width: 100%;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: #aaaaaa;
  }
}

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inner {
  display: flex;
  justify-content: space-between;
}

.modeContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 25px;
  color: #000;
}

.modeButtons {
  display: flex;
  margin-top: 5px;

  .button {
    width: 35px;
    height: 35px;
  }

  .leftMode {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .centerMode {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-right: 0;
    border-left: 0;

    &.currentMode {
      svg {
        path {
          fill: #1f80b4;
        }

        circle {
          fill: #fff;
        }
      }
    }

    svg {
      path {
        fill: #fff;
      }
    }

    &:hover {
      svg {
        path {
          fill: #1f80b4;
        }

        circle {
          fill: #fff;
        }
      }
    }
  }

  .rightMode {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.iconCamera {
  margin-right: 10px;

  path {
    fill: #ffffff;
  }

  rect {
    fill: #1f80b4;
  }
}

.title {
  margin-bottom: 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.buttonClose {
  background: none;
  border: none;
  outline: none;

  &:focus {
    outline: none;
  }
}

.iconClose {
  path {
    fill: #ff3838;
  }
}

.parameters {
  margin: 30px 0 0 0;
  padding: 0;
  list-style: none;
}

.item {
  margin-bottom: 15px;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
