.select-wrap {
  position: relative;
  width: 100%;
  margin-right: 30px;
  display: flex;
  flex-direction: column;

  &:after {
    content: '';
    display: block;
    pointer-events: none;
    background: url('./select-icon.svg') center center no-repeat;
    width: 12px;
    height: 6px;
    right: 15px;
    bottom: 15px;
    position: absolute;
    z-index: 1;
  }

  &__label {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #a8a8a8;
  }

  &__select {
    display: block;
    width: 100%;
    padding: 0 35px 0 20px;
    background: none;
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
    border-radius: 6px;
    -webkit-appearance: none;
    appearance: none;
    padding: 8px 15px;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    outline: none;

    &-h36 {
      line-height: 34px;
    }
  }

  &__select:focus {
    outline: 0;
    border-color: rgba(77, 124, 254, 0.3);
  }
}
