.container {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  min-width: 150px;
  padding: 5px 0;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.31) 0px 0px 1px;
  border-radius: 4px;
  background: #fff;
  z-index: 999;
}

.option {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 12px;
  text-align: left;
  background: none;
  border: none;
  outline: none;

  &:focus {
    outline: none;
  }

  svg {
    margin-right: 8px;
  }

  &:hover {
    background: #f2f2f2;
  }
}
