.lds-ring {
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;

  &.small {
    width: 50px;
    height: 50px;
  }
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #8fbfda;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #cef transparent transparent transparent;
}

.lds-ring.small div {
  width: 42px;
  height: 42px;
  margin: 4px;
  border-width: 4px;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
