.table {
  .head {
    display: flex;
    align-items: center;
    padding: 18px 20px;
  }

  .th {
    font-size: 16px;
    color: #a8a8a8;
    padding: 0 5px;
  }

  .td {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #000;
    word-break: break-all;
    padding: 0 5px;
  }

  .row {
    display: flex;
    align-items: center;
    padding: 16px 20px;
    margin-bottom: 8px;
    background: #fff;
    border-radius: 6px;
    transition: background 0.3s ease;

    &:hover {
      background: #deeef7;
      cursor: pointer;
    }
  }

  .flex {
    flex: 1;
  }

  .paddingRight {
    margin-right: 8px;
  }

  .blue {
    color: #1f80b4;
  }

  .gray {
    color: #a8a8a8;
  }

  .bold {
    font-weight: bold;
  }

  .capitalize {
    text-transform: capitalize;
  }
}

.statusCircle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
}

.red {
  background: #e85c5c;
}

.orange {
  background: #ffad32;
}

.green {
  background: #54d771;
}

.turnaroundMode {
  position: relative;
}

.mode {
  padding-left: 12px;
  margin-bottom: 5px;
  color: #fff;
  text-transform: capitalize;
}

.modeLabel {
  padding-right: 3px;
  color: rgba(255, 255, 255, 0.6);
  font-weight: bold;
}

.modePopup {
  position: absolute;
  right: calc(100% + 15px);
  top: 0;
  min-width: 200px;
  padding: 7px 0;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.31) 0px 0px 1px;
  border-radius: 4px;
  background: #1f80b4;
  z-index: 999;

  .item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 4px 12px;
    color: #fff;
    text-transform: capitalize;
  }

  .title {
    color: rgba(255, 255, 255, 0.6);
    font-weight: bold;
  }

  & .action {
    cursor: pointer;
    background: #1f80b4;
    transition: all 0.3s ease;

    &:hover {
      background: darken(#1f80b4, 5%);
    }
  }
}

.alerts {
  position: relative;

  .numberOfAlerts {
    position: absolute;
    right: -2px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
    background: #e85c5c;
    color: #fff;
    border-radius: 50%;
    font-size: 9px;
  }
}
