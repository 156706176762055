.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex: 1;
  display: flex;
  margin-top: 30px;
  padding: 30px;
  background-color: #fff;
  border-radius: 6px;
}

.left {
  flex-basis: 25%;
}

.center {
  flex-basis: 40%;
  border-left: 1px solid #a8a8a8;
  border-right: 1px solid #a8a8a8;
}

.right {
  flex-basis: 35%;
}

.searchBox {
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 15px;
  padding-top: 10px;
}

.apiList {
  margin-top: 15px;
}
