.gateSwitch {
  display: flex;
  align-items: center;
  position: relative;

  .switch {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .switchLeft {
    transform: rotate(-180deg);
  }

  .currentGate {
    margin: 0 10px;
  }
}

.filterContainer {
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
  width: 200px;
  background: #fff;
  border: 1px solid #1f80b4;
  border-radius: 6px;

  .filter {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: #1f80b4;
    cursor: pointer;
  }

  .icon {
    width: 15%;
  }

  .bold {
    font-weight: bold;
  }
}
