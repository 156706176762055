.container {
  display: flex;
  align-items: center;
}

.icon {
  fill: #a8a8a8;
}

.input {
  max-width: 150px;
  margin-left: 10px;
  background: none;
  border: none;

  &::placeholder {
    color: rgba(#a8a8a8, 0.6);
  }
}
