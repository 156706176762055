.client-input {
  margin-top: 30px;
}

.client-user-type-choice {
  display: flex;
  align-items: center;

  & > * {
    width: auto;
  }

  .title {
    margin-right: 30px;
  }
}

.add-new-account {
  height: 100%;
  position: relative;

  .form-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .selected-airports {
    max-height: 500px;
    overflow: auto;
    padding-bottom: 100px;
  }

  .no-airport {
    background: #f0f2f8;
    border-radius: 6px;
    padding: 30px 0;
    text-align: center;
  }

  svg {
    path {
      fill: #1f80b4;
    }

    circle {
      fill: #8fbfda;
    }
  }

  .delete-item {
    path {
      fill: #ff3838;
    }

    &:hover {
      path {
        fill: #ffffff;
      }

      path:first-child {
        fill: #ff3838;
        opacity: 1;
      }
    }
  }

  &__container {
    &_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 40px;
      margin-bottom: 40px;
      border-bottom: 1px solid #e8e8e8;
      border-bottom: none;
    }
  }

  &__permission-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
  }

  &__label {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #a8a8a8;
  }

  &__radio-group {
    margin-top: 20px;
    display: flex;
    align-items: center;

    & > * {
      width: auto !important;
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .my-input {
    max-width: 350px;
    width: 100%;
  }

  &__footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-top: 40px;
    display: flex;
    align-items: center;

    &_justify {
      justify-content: space-between;
    }

    & > * {
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .airport {
    &__head {
      height: 80px;
      padding-bottom: 0 !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #e8e8e8;

      .settings {
        margin-right: 12px;
        background: none;
        border: none;
        outline: none;
        color: #1f80b4;
        text-align: center;
      }
    }

    &__row {
      display: flex;
      align-items: center;
    }

    &__group {
      display: flex;
      align-items: center;
      width: 50%;

      &_end {
        justify-content: flex-end;
        width: calc(50% - 40px);
      }

      .action {
        &_empty {
          width: 36px;
          height: 40px !important;
          display: flex;
          align-items: center;
          justify-content: center;
          background: none;
          border: none;
          outline: none;
        }
      }
    }

    &__col {
      width: 50%;
      padding: 10px 15px;

      .event {
        margin: 0;
        width: 100%;
        padding: 8px;
        border: none;
        outline: none;
        color: #1f80b4;
        text-align: center;
        background: #f0f2f8;
        border-radius: 5px;
      }
    }

    &__name {
      width: 180px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__country {
      width: 40px;
      text-align: center;
    }

    &__name,
    &__country {
      margin: 0 15px 0 0;
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      color: #1f80b4;
    }

    &__view {
      margin: 0 15px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #9f9f9f;
    }
  }

  .role-container {
    width: 150px;
    margin-left: 20px;
  }

  .add-new-airport-button {
    .select-wrap {
      margin-right: 10px;
      max-width: 350px;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;

      &__label {
        margin-bottom: 0;
        margin-right: 5px;
        text-transform: none;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #000000;
      }
    }

    .my-btn {
      min-width: 120px;
      padding: 6px 12px;
    }
  }

  .accordeon {
    margin-top: 20px;
    display: block;

    &-item {
      width: 100%;
      border-bottom: 1px solid #e8e8e8;

      &_active {
        border-bottom: 1px solid transparent;

        .accordeon-item__title {
          font-weight: bold;
          color: #000000;
        }

        .accordeon-item__id {
          color: #1f80b4;
          border: 1px solid #1f80b4;
        }
      }

      &__wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &__title {
        margin: 0 10px 0 0;
        font-size: 16px;
        line-height: 19px;
        color: #a8a8a8;
      }

      &__id {
        font-size: 16px;
        line-height: 19px;
        color: #a8a8a8;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        border: 1px solid #a8a8a8;
        box-sizing: border-box;
        border-radius: 4px;
      }

      &__edit {
        margin-left: auto;
        margin-right: 6px;
        cursor: pointer;
      }
    }

    &-inner-item {
      padding: 10px 0;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e8e8e8;

      &-wrap {
        padding: 0 0 0 40px;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        border-top: 1px solid #1f80b4;
      }

      &__title {
        margin: 0 10px 0 0;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
      }

      &__id {
        font-size: 16px;
        line-height: 19px;
        color: #1f80b4;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        background: #f0f2f8;
        box-sizing: border-box;
        border-radius: 4px;
      }

      &-row {
        display: flex;
        flex-direction: column;
        align-items: stretch;

        .action {
          &_empty {
            display: block;
            width: 36px;
            height: 57px !important;
          }

          &_icon {
            margin-right: 0;
            padding: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
          }
        }

        .col {
          display: flex;
          align-items: center;
          border-bottom: 1px solid transparent;

          &_border {
            padding: 10px 15px;
            border-bottom: 1px solid #e8e8e8;
          }

          &__text {
            font-size: 16px;
            line-height: 19px;
            color: #000000;
          }
        }
      }

      .col {
        display: flex;
        align-items: center;

        &__title {
          margin-bottom: 0;
          font-size: 14px;
          line-height: 16px;
          text-transform: uppercase;
          color: #a8a8a8;
        }
      }

      .table__col_icon {
        padding: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
      }
    }

    &-add {
      display: flex;
      justify-content: flex-end;
      margin: 20px 0 40px;
      align-items: center;

      &-box {
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: all 0.4s;

        &:hover {
          svg path {
            fill: #0d5378;
          }

          .accordeon-add__title {
            color: #0d5378;
          }
        }
      }

      &__title {
        font-size: 16px;
        line-height: 19px;
        color: #1f80b4;
      }

      &__icon {
        path {
          fill: #1f80b4;
        }
      }

      &-new {
        max-width: 450px;
        width: 100%;

        &__current {
          margin-bottom: 15px;
          padding-bottom: 15px;
          display: flex;
          align-items: center;
          font-size: 16px;
          line-height: 19px;
          color: #1f80b4;
          border-bottom: 1px solid #e8e8e8;

          &_check {
            padding-bottom: 0;
            font-weight: normal;
            color: #000000;
            border-bottom: none;
          }

          &-id {
            margin-left: 10px;
            font-size: 16px;
            line-height: 19px;
            color: #1f80b4;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25px;
            height: 25px;
            border: 1px solid #1f80b4;
            box-sizing: border-box;
            border-radius: 4px;
          }
        }

        &__gate {
          margin-bottom: 15px;
          display: flex;
          align-items: center;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          color: #000000;

          &-id {
            margin-left: 10px;
            font-size: 16px;
            line-height: 19px;
            color: #1f80b4;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25px;
            height: 25px;
            border: 1px solid #f0f2f8;
            box-sizing: border-box;
            background: #f0f2f8;
            border-radius: 4px;
          }
        }

        &__title {
          margin-top: 10px;
          margin-bottom: 20px;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          text-transform: uppercase;
          color: #a8a8a8;
        }

        &__list {
          margin-bottom: 25px;
          padding-bottom: 10px;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          max-height: 140px;
          height: 100%;
          border-bottom: 1px solid #e8e8e8;

          & > * {
            max-width: 150px;
            width: 100%;
            margin-bottom: 15px;
          }

          &_inner {
            padding-left: 60px;
          }

          &_check {
          }
        }

        &__next {
          display: flex;
          align-items: center;
          cursor: pointer;

          svg {
            margin-right: 10px;
          }

          span {
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            color: #1f80b4;
          }

          &:hover {
            svg path {
              fill: #0d5378;
            }

            span {
              color: #0d5378;
            }
          }
        }

        &__back {
          margin-right: 25px;
          display: flex;
          align-items: center;
          cursor: pointer;

          svg {
            margin-right: 10px;

            path {
              fill: #8fbfda;
            }
          }

          span {
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            color: #8fbfda;
          }

          &:hover {
            svg path {
              fill: #1f80b4;
            }

            span {
              color: #1f80b4;
            }
          }
        }

        .footer {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .my-popup {
    &__content {
      position: relative;
      min-width: 600px;
      width: 100%;
      max-height: 630px;
      background: #ffffff;
    }

    &__title {
      margin-bottom: 30px;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      color: #000000;
    }

    &__close {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      background: #f0f2f8;
      border: none;
      outline: none;
      border-radius: 50%;
      position: absolute;
      top: 20px;
      right: 20px;
    }

    &__box {
      margin-bottom: 10px;
      overflow: hidden;
      max-height: 448px;
      overflow-y: scroll;
      display: flex;
      align-items: stretch;
      justify-content: space-between;
    }

    &__col {
      width: 50%;
      margin-right: 30px;
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      &:last-child {
        margin-right: 0;
      }

      & > * {
        width: 100%;
      }
    }

    &__row {
      display: flex;
      align-items: center;

      &_end {
        justify-content: flex-end;
      }
    }

    .save-line {
      margin: 0 -40px -40px;
    }
  }

  .check-group {
    margin-bottom: 10px;
    &__title {
      margin-bottom: 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
    }

    &__items {
      margin: 0 0 20px 0;
      padding: 0 0 0 26px;
      display: flex;
      align-items: stretch;
      flex-direction: column;

      & > * {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.add-new-account-2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: unset;

  .add-new-account__footer {
    position: unset;
  }
}

.add-new-account {
  height: 100%;
  position: relative;

  .edit-item {
    cursor: pointer;

    path {
      fill: #8fbfda;
    }

    &:hover path {
      fill: #1f80b4;
    }
  }

  &__container {
    margin-top: 40px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e8e8e8;

    &_no-border {
      border-bottom: none;

      & > * {
        margin-top: 30px;
      }
    }
  }

  &__title {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
    margin-bottom: 30px;
  }

  &__label {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #a8a8a8;
  }

  &__radio-group {
    margin-top: 20px;
    display: flex;
    align-items: center;

    & > * {
      width: auto !important;
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .my-input {
    max-width: 350px;
    width: 100%;
  }

  &__footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-top: 40px;
    display: flex;
    align-items: center;

    &_justify {
      justify-content: space-between;
    }

    & > * {
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .airport {
    &__head {
      padding-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #e8e8e8;
    }

    &__row {
      display: flex;
      align-items: center;
    }

    &__group {
      display: flex;
      align-items: center;
      width: 50%;

      &_end {
        justify-content: flex-end;
        width: calc(50% - 40px);
      }

      .action {
        &_empty {
          width: 36px;
          height: 40px !important;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    &__col {
      width: 50%;
      padding: 10px 15px;

      .event {
        margin: 0;
        width: 100%;
        padding: 8px;
        color: #1f80b4;
        text-align: center;
        background: #f0f2f8;
        border-radius: 5px;
        cursor: pointer;
      }
    }

    &__name,
    &__country {
      margin: 0 15px 0 0;
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      color: #1f80b4;
    }

    &__view {
      margin: 0 15px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #9f9f9f;
    }
  }

  .add-new-airport {
    margin-top: 40px;

    .select-wrap {
      margin-right: 10px;
      max-width: 350px;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;

      &__label {
        margin-bottom: 0;
        margin-right: 5px;
        text-transform: none;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #000000;
      }
    }

    .my-btn {
      min-width: 120px;
      padding: 6px 12px;
    }
  }

  .accordeon {
    margin-top: 20px;
    display: block;

    &-item {
      width: 100%;
      border-bottom: 1px solid #e8e8e8;

      &_active {
        border-bottom: 1px solid transparent;

        .accordeon-item__title {
          font-weight: bold;
          color: #000000;
        }

        .accordeon-item__id {
          color: #1f80b4;
          border: 1px solid #1f80b4;
        }
      }

      &__wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &__title {
        margin: 0 10px 0 0;
        font-size: 16px;
        line-height: 19px;
        color: #a8a8a8;
      }

      &__id {
        font-size: 16px;
        line-height: 19px;
        color: #a8a8a8;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        border: 1px solid #a8a8a8;
        box-sizing: border-box;
        border-radius: 4px;
      }

      &__edit {
        margin-left: auto;
        margin-right: 6px;
        cursor: pointer;
      }
    }

    &-inner-item {
      padding: 10px 0;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e8e8e8;

      .event {
        padding: 8px;
        color: #1f80b4;
        width: 100%;
        text-align: center;
        background: #f0f2f8;
        border-radius: 5px;
      }

      &-wrap {
        padding: 0 0 0 40px;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        border-top: 1px solid #1f80b4;
      }

      &__title {
        margin: 0 10px 0 0;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
      }

      &__id {
        font-size: 16px;
        line-height: 19px;
        color: #1f80b4;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        background: #f0f2f8;
        box-sizing: border-box;
        border-radius: 4px;
      }

      &-row {
        display: flex;
        flex-direction: column;
        align-items: stretch;

        .action {
          &_empty {
            display: block;
            width: 36px;
            height: 57px !important;
          }

          &_icon {
            margin-right: 0;
            padding: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
          }
        }

        .col {
          display: flex;
          align-items: center;
          border-bottom: 1px solid transparent;

          &_border {
            padding: 10px 15px;
            border-bottom: 1px solid #e8e8e8;
          }

          &__text {
            font-size: 16px;
            line-height: 19px;
            color: #000000;
          }

          .event {
            padding: 8px;
            color: #1f80b4;
            width: 100%;
            text-align: center;
            background: #f0f2f8;
            border-radius: 5px;
          }
        }
      }

      .col {
        display: flex;
        align-items: center;

        &__title {
          margin-bottom: 0;
          font-size: 14px;
          line-height: 16px;
          text-transform: uppercase;
          color: #a8a8a8;
        }
      }

      .table__col_icon {
        padding: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
      }
    }

    &-add {
      display: flex;
      justify-content: flex-end;
      margin: 20px 0 40px;
      align-items: center;

      &-box {
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: all 0.4s;

        &:hover {
          svg path {
            fill: #0d5378;
          }

          .accordeon-add__title {
            color: #0d5378;
          }
        }
      }

      &__title {
        font-size: 16px;
        line-height: 19px;
        color: #1f80b4;
      }

      &__icon {
        path {
          fill: #1f80b4;
        }
      }

      &-new {
        max-width: 450px;
        width: 100%;

        &__current {
          margin-bottom: 15px;
          padding-bottom: 15px;
          display: flex;
          align-items: center;
          font-size: 16px;
          line-height: 19px;
          color: #1f80b4;
          border-bottom: 1px solid #e8e8e8;

          &_check {
            padding-bottom: 0;
            font-weight: normal;
            color: #000000;
            border-bottom: none;
          }

          &-id {
            margin-left: 10px;
            font-size: 16px;
            line-height: 19px;
            color: #1f80b4;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25px;
            height: 25px;
            border: 1px solid #1f80b4;
            box-sizing: border-box;
            border-radius: 4px;
          }
        }

        &__gate {
          margin-bottom: 15px;
          display: flex;
          align-items: center;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          color: #000000;

          &-id {
            margin-left: 10px;
            font-size: 16px;
            line-height: 19px;
            color: #1f80b4;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25px;
            height: 25px;
            border: 1px solid #f0f2f8;
            box-sizing: border-box;
            background: #f0f2f8;
            border-radius: 4px;
          }
        }

        &__title {
          margin-top: 10px;
          margin-bottom: 20px;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          text-transform: uppercase;
          color: #a8a8a8;
        }

        &__list {
          margin-bottom: 25px;
          padding-bottom: 10px;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          max-height: 140px;
          height: 100%;
          border-bottom: 1px solid #e8e8e8;

          & > * {
            max-width: 150px;
            width: 100%;
            margin-bottom: 15px;
          }

          &_inner {
            padding-left: 60px;
          }

          &_check {
          }
        }

        &__next {
          display: flex;
          align-items: center;
          cursor: pointer;

          svg {
            margin-right: 10px;
          }

          span {
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            color: #1f80b4;
          }

          &:hover {
            svg path {
              fill: #0d5378;
            }

            span {
              color: #0d5378;
            }
          }
        }

        &__back {
          margin-right: 25px;
          display: flex;
          align-items: center;
          cursor: pointer;

          svg {
            margin-right: 10px;

            path {
              fill: #8fbfda;
            }
          }

          span {
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            color: #8fbfda;
          }

          &:hover {
            svg path {
              fill: #1f80b4;
            }

            span {
              color: #1f80b4;
            }
          }
        }

        .footer {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .check-group {
    &-header {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
    }

    &__icon {
      margin: 0 0 0 -10px;
    }

    &__title {
      margin-bottom: 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
    }

    &__items {
      margin: 0 0 20px 0;
      padding: 0 0 0 26px;
      display: flex;
      align-items: stretch;
      flex-direction: column;

      & > * {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.add-new-account-2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: unset;

  .add-new-account__footer {
    position: unset;
  }
}

.account-settings {
  .myLabelClass {
    z-index: 10;
    padding-right: 25px;
    min-width: 200px;
    min-height: 25px;
    margin-bottom: 0;
  }

  .myInputClass {
    z-index: 10;
    padding-right: 25px;
    outline: none;
    background: transparent;
    border: none;
    border-bottom: 1px solid #1f80b4;
  }

  .edit-under {
    z-index: 0;
    margin-left: -41px;
  }

  .col_edit {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .table__col_icon {
      margin-right: -15px;
    }
  }
}
