.button {
  padding: 8px 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #E9F3F8;
  color: #1f80b4;
  font-weight: 500;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  transition: all 0.2s;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: #1f80b4;
    color: #fff;
  }
}

.marginRight {
  margin-right: 10px;
}

.icon {
  svg {
    path {
      transition: fill 0.2s ease;
      fill: #fff;
    }
  }
}

.secondary {
  background: transparent;
  border: 1px solid #1f80b4;
  color: #1f80b4;

  .icon {
    svg {
      path {
        fill: #1f80b4;
      }
    }
  }

  &:hover {
    background: #156fa0;
    color: #fff;

    .icon {
      svg {
        path {
          fill: #fff;
        }
      }
    }
  }
}

.secondary-light {
  background: #f0f2f8;
  color: #1f80b4;

  &:hover {
    //background: darken(#f0f2f8, 3%);
    background: #1f80b4;
    color: #f0f2f8;
  }
}
