.container {
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 20px;

  .critical {
    display: flex;
    align-items: center;
  }
}

.airport {
  color: #383838;
}

.airportPermissions {
  flex: 1;
  padding: 10px 20px;
  background: #fafafa;
  border-radius: 6px;
  margin-top: 20px;
  transition: background 0.3s ease;
  cursor: pointer;

  &:hover {
    background: darken(#fafafa, 5%);
  }
}

.saveButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}
