.container {
  display: grid;
  grid-template-rows: 80px auto 120px;
  grid-template-columns: auto;
  height: 100%;
}

.header {
  border-bottom: 1px solid #e8e8e8;
}

.headerInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  .leftSide {
    display: flex;
    justify-content: space-between;
    align-items: start;
    flex: 1;
  }

  .rightSide {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-right: 1px solid #dbdbdb;
    margin-right: 40px;
    padding-right: 40px;
  }

  .gateSwitch {
    padding: 0 30px;
    margin: 0 40px;
    border-right: 1px solid #dbdbdb;
    border-left: 1px solid #dbdbdb;
  }
}

.headerTitle {
  color: #000;
}

.detailsButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 180px;
  cursor: pointer;
  color: #1f80b4;

  &:focus {
    outline: none;
  }

  .title {
    font-weight: bold;
    margin-bottom: 0;
  }
}

.content {
  flex: 1;
  margin-top: 15px;
}

.table {
  .head {
    display: flex;
    align-items: center;
    padding: 18px 20px;
  }

  .th {
    font-size: 16px;
    color: #a8a8a8;
  }

  .td {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #000;
    word-break: break-all;
  }

  .row {
    display: flex;
    align-items: center;
    padding: 16px 20px;
    margin-bottom: 8px;
    background: #fff;
    border-radius: 6px;
    transition: background 0.3s ease;

    &:hover {
      background: #deeef7;
      cursor: pointer;
    }
  }

  .flex {
    flex: 1;
  }

  .paddingRight {
    margin-right: 8px;
  }

  .blue {
    color: #1f80b4;
  }

  .gray {
    color: #a8a8a8;
  }

  .bold {
    font-weight: bold;
  }

  .capitalize {
    text-transform: capitalize;
  }
}
