.container {
  padding: 15px;
  border: 1px solid #1f80b4;
  border-radius: 4px;
}

.parameter {
  display: flex;
  align-items: center;
  margin-top: 5px;

  .value {
    margin-left: 10px;
    color: #000;
  }

  .timeInput {
    width: 100px;
  }
}

.buttons {
  display: flex;
  align-items: center;
}

.deleteButton {
  padding: 5px 10px;
  margin-left: 15px;
}
