.view-button {
  margin-right: 15px;
}

.flight-tracker-search-box {
  padding: 7px 15px;
  border: 1px solid #a8a8a8;
  border-radius: 4px;
}

.airplane-detail {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 99;
  background: #ffffff;
  display: flex;
  width: 100%;
  box-shadow: 0px -10px 40px rgba(30, 35, 41, 0.06);

  .main.airplane {
    height: auto;
    min-height: auto;
    padding: 10px 30px 50px;
    width: 100%;
  }

  .table-content {
    overflow: visible;
    margin-bottom: 0;
    margin-top: 0;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e8e8e8;
    //padding: 0 0 16px 0;

    .airplane-detail-header-content {
      display: flex;
      justify-content: space-between;
      flex: 1;

      .separator {
        width: 1px;
        margin-right: 20px;
        background: rgb(232, 232, 232);
      }
    }

    &__column {
      display: flex;
      flex: 1;
      flex-direction: column;

      @media screen and (min-width: 1950px) {
        flex-direction: row;
        align-items: center;
        position: relative;
        bottom: -1px;
      }

      &:last-child {
        margin: 0;
      }
    }

    &__title {
      margin: 0 0 5px 0;
      width: max-content;

      @media screen and (min-width: 1800px) {
        margin: 0 13px 0 0;
      }
    }

    &__text {
      width: 80%;
      font-weight: bold;
      color: #1f80b4;

      &_red {
        color: #e85c5c;
      }
    }

    &-button-group {
      display: flex;
      align-items: center;
    }
  }

  &-view {
    background: #f0f2f8;
    border-radius: 6px;
    color: #1f80b4;
    padding: 11px 27px;
    margin: 0 19px 0 0;
    transition: all 0.3s;

    &:hover {
      color: #f0f2f8;
      background: #1f80b4;
    }
  }

  &-close {
    path {
      transition: all 0.3s;
      fill: #1f80b4;
    }

    &:hover {
      & path:nth-child(1) {
        opacity: 1;
        fill: #1f80b4;
      }
      & path:nth-child(2) {
        fill: #f0f2f8;
      }
      & path:nth-child(3) {
        fill: #f0f2f8;
      }
    }
  }
}

.airplane-detail-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  color: #1f80b4;
  //margin: 15px 0 0;

  &__column {
    display: flex;
    flex-direction: column;
    width: 18%;
    margin-bottom: 12px;

    &:nth-child(6) {
      width: 38.6%;
    }
  }

  &-line.red {
    background: #fadede;
  }

  &-line.green {
    background: #ddf7e3;
  }

  &-line {
    position: relative;
    height: 14px;
    background: #f2f4f9;
    border-radius: 3px;
    margin: 5px 0;

    &__progress,
    &__progress__active {
      position: absolute;
      left: 0;
      top: 0;
      height: inherit;
      width: 10px;
      z-index: 1;
      border-radius: 3px;
    }

    &__val.red {
      color: #e85c5c;
    }

    &__progress.red,
    &__progress__active.red {
      background: #e85c5c;
      &:before {
        background: #e85c5c;
      }
    }

    &__val.green {
      color: #54d771;
    }

    &__progress.green,
    &__progress__active.green {
      background: #54d771;
      &:before {
        background: #54d771;
      }
    }

    &__progress__active {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;

      &:before {
        display: block;
        z-index: 2;
        content: '';
        width: 2px;
        height: 22px;
        border-radius: 3px;
        position: absolute;
        right: -4px;
        top: -4px;
      }
    }
  }

  &__value {
    display: flex;
    justify-content: space-between;
    color: #a8a8a8;
  }
}

.airplane-detail-header,
.airplane-detail-content {
  @media screen and (min-width: 1441px) {
    margin-right: 17px;
    margin-left: 17px;
  }
}
