.table-header {
  width: 100%;
  &-action {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .datepicker {
    display: flex;
    align-items: center;

    &__label {
      margin-right: 5px;
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
      color: #a8a8a8;
    }

    .react-daterange-picker__wrapper {
      border: none;
      font-weight: 500;
      font-size: 16px;
    }

    .react-daterange-picker__calendar {
      bottom: -20px !important;
      left: -60px !important;
    }

    .react-daterange-picker__inputGroup {
      input {
        color: #1f80b4;
      }
    }

    .react-daterange-picker__clear-button,
    .react-daterange-picker__calendar-button {
      outline: none;

      svg {
        stroke: #1f80b4;
      }
    }

    .react-calendar {
      padding: 15px;
      background: #ffffff;
      border: 1px solid #8fbfda;
      box-sizing: border-box;
      border-radius: 6px;

      abbr {
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: #1f80b4;
      }

      .react-calendar__tile {
        border-radius: 4px;
      }

      .react-calendar__tile--active {
        background: #f0f2f8;
        border-radius: 0;
      }

      .react-calendar__tile--rangeStart {
        border-radius: 4px 0 0 4px;
      }

      .react-calendar__tile--hasActive,
      .react-calendar__tile--rangeStart,
      .react-calendar__tile--rangeEnd {
        background: #1f80b4;
        border-radius: 4px;

        abbr {
          color: #ffffff;
        }
      }

      .react-calendar__tile--rangeStart {
        border-radius: 4px 0 0 4px;
      }

      .react-calendar__tile--rangeEnd {
        border-radius: 0 4px 4px 0;
      }
    }
  }

  .button-group {
    display: flex;
    align-items: center;

    & > * {
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }

    .table-action {
      &-btn {
        padding: 12px 30px;
        min-width: 130px;
        width: 100%;
        display: flex;
        align-items: center;
        background: #1f80b4;
        border-radius: 6px;
        cursor: pointer;
        transition: all 0.3s;

        &__img {
          margin-right: 8px;

          path {
            fill: #ffffff;
          }
        }

        &__text {
          width: 100%;
          text-align: center;
          font-weight: 500;
          font-size: 16px;
          line-height: 25px;
          color: #ffffff;
        }

        &:hover {
          background: #0d5378;
        }

        // --bordered
        &--bordered {
          padding: 11px 29px;
          background: transparent;
          border: 1px solid #1f80b4;
          box-sizing: border-box;
          border-radius: 6px;

          .table-action-btn__text {
            color: #1f80b4;
          }

          .table-action-btn__img path {
            fill: #1f80b4;
          }

          &:hover {
            background: #0d5378;
            border: 1px solid #0d5378;

            svg path {
              fill: #ffffff;
            }

            .table-action-btn__text {
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}
