.container {
  display: flex;
  justify-content: space-between;
}

.left {
  flex: 1;
}

.name {
  color: #303030;
}

.type {
  font-size: 14px;
}

.input {
  flex-basis: 60%;
  margin-left: 30px;
}

.description {
  margin-top: 8px;
  font-size: 14px;
}
