.modal {
  width: 650px;
}

.container {
}

.header {
  display: flex;
}

.tag {
  display: flex;
  padding: 10px;
  margin-right: 15px;
  background: #f0f2f8;
  border-radius: 6px;
  font-size: 14px;
  color: #1f80b4;

  .label {
    font-weight: bold;
    margin-right: 5px;
  }
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 30px;
  background: #f9faff;
  border-radius: 6px;
  overflow: hidden;

  .image {
    width: 100%;
    height: 280px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img {
      max-width: 100%;
      min-height: 100%;
      object-fit: cover;
    }
  }

  .info {
    width: 100%;
    padding: 30px;
    border: 1px solid #e8e8e8;
  }

  .infoItem {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .label {
      display: flex;
      align-items: center;

      .labelText {
        color: #000;
        margin-left: 6px;
      }
    }
  }
}

.feedbackContainer {
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px solid #e8e8e8;
}

.autoFeedback {
  width: 100%;
  margin-top: 10px;
}

.customFeedback {
  width: 100%;
  margin-top: 10px;
}

.feedbackButton {
  margin-left: auto;
  margin-top: 20px;
}
