.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.saveButton {
  margin-top: 20px;
}

.inner {
  height: 650px;
  width: 100%;
}

.gateConesList {
  max-height: 150px;
  overflow: auto;
  margin-top: 20px;
}

.conesContainer {
  display: flex;
  margin-bottom: 15px;

  .gate {
    margin-right: 10px;
    color: #000;
  }
}
