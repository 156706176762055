.container {
  position: relative;
}

.filterContainer {
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
  width: 220px;
  background: #fff;
  border: 1px solid #1f80b4;
  border-radius: 6px;

  .filter {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: #1f80b4;
    cursor: pointer;
  }

  .icon {
    width: 15%;
  }

  .bold {
    font-weight: bold;
  }
}
