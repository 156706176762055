.no-flights-message {
  margin-top: 70px;
  text-align: center;
}

.expected-status {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 23px;
  border-radius: 4px;
  background: #f0f2f8;
  z-index: 1;
  outline: none;
}

.real-status {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 23px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  z-index: 2;
  outline: none;
  cursor: pointer;
}

.timeline {
  position: absolute;
  width: 2px;
  height: 100%;
  background: #1f80b4;
  z-index: 4;

  &-arrow-top {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    fill: #1f80b4;
  }

  &-arrow-bottom {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    fill: #1f80b4;
  }
}

.table-max__width {
  max-width: 1229px;
  @media screen and (min-width: 1441px) {
    max-width: 100%;
  }
}

.table-content {
  margin-top: 30px;
  overflow-x: auto;
  overflow-y: scroll;
  position: relative;

  &-header {
    position: sticky;
    width: max-content;
    top: 0;
    //z-index: 100;
    z-index: 21;
    background: #f9faff;

    > div {
      background: #f9faff;
    }
  }

  &-columns {
    display: flex;

    &:focus {
      outline: none;
    }

    &__arrow {
      margin: 0 0 0 8px;
      fill: #a8a8a8;
    }

    &__width {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
    }
  }

  &-columns.title {
    position: sticky;
    left: 0;
    width: 350px;
    padding-bottom: 4px;

    .table-content-columns__width {
      min-width: 105px;
      word-break: break-word;
      border-radius: 6px 0 0 6px;
      padding: 10px 15px;
      justify-content: flex-start;

      &:nth-child(1) {
        min-width: 35px;
      }

      &:nth-child(2) {
        font-weight: bold;
      }

      &.turnaroundMode {
        display: flex;
        align-items: center;
        position: relative;

        &:focus {
          outline: none !important;
        }
      }

      .mode {
        padding-left: 12px;
        margin-bottom: 5px;
        color: #fff;
        text-transform: capitalize;
      }

      .modeLabel {
        padding-right: 3px;
        color: rgba(255, 255, 255, 0.6);
        font-weight: bold;
      }

      .modeDetailsPopup {
        position: absolute;
        left: calc(100% + 10px);
        top: 10px;
        min-width: 220px;
        padding: 7px 0;
        box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.31) 0px 0px 1px;
        border-radius: 4px;
        background: #1f80b4;
        z-index: 999;

        .camerasPopup {
          position: absolute;
          left: calc(100% + 10px);
          top: 30px;
          min-width: 180px;
          width: max-content;
        }

        .item {
          display: flex;
          justify-content: space-between;
          width: 100%;
          padding: 4px 12px;
          color: #fff;
          text-transform: capitalize;
        }

        .camera-name {
          max-width: 200px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin-right: 15px;
          text-transform: initial;
        }

        .title {
          color: rgba(255, 255, 255, 0.6);
          font-weight: bold;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        & .action {
          cursor: pointer;
          background: #1f80b4;
          transition: all 0.3s ease;

          &:hover {
            background: darken(#1f80b4, 5%);
          }
        }
      }
    }
  }

  .table-content-row {
    position: relative;

    &__width {
      justify-content: flex-start;
    }

    .table-content-columns {
      &-status {
        min-width: 116px;
      }
    }
  }

  &-row {
    color: #1f80b4;
    .table-content-columns.time {
      position: relative;

      .table-content-columns__width {
        justify-content: flex-start;
      }
    }

    .table-content-columns {
      &__width {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffff;
        border-left: 2px solid #f0f2f8;

        &:after {
          position: absolute;
          left: -2px;
          top: 0;
          content: ' ';
          height: 140%;
          background: #f0f2f8;
          width: 2px;
        }
      }

      .table-content-columns.title {
        background: #f9faff;

        &:after {
          position: absolute;
          right: -15px;
          content: ' ';
          display: block;
          height: 100%;
          width: 15px;
          background: linear-gradient(90deg, rgba(162, 162, 162, 0.2) 0%, rgba(221, 221, 221, 0.05) 100%);
        }

        .table-content-columns__width {
          border-left: none;
          &:after {
            display: none;
          }
        }
      }

      &-status {
        min-width: 59px;
        height: 23px;
        border-radius: 4px;
        z-index: 1;
        outline: none;

        &__progress {
          width: 0;
          height: 100%;
          border-radius: 4px;
          z-index: 2;
        }

        &__progress.ready {
          background: #54d771;
        }

        &__progress.ready.active {
          box-shadow: 0 0 0 4px rgba(221, 247, 227, 1);
        }

        &__progress.delayed {
          background: #e85c5c;
        }

        &__progress.delayed.active {
          box-shadow: 0 0 0 4px rgba(250, 222, 222, 1);
        }

        &__progress.waiting {
          background: #f2f4f9;
        }

        &__progress.suspense {
          background: #f9c84b;
        }
      }

      &-status.empty {
        display: none;
      }
    }
  }
  .table-content-row {
    width: max-content;
    > .table-content-columns {
      &:last-child {
        .table-content-columns__width {
          &:after {
            height: 100%;
          }
        }
      }
    }
  }
}
