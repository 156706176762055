@import 'theme/theme';

.app {
  width: 100%;
  height: 100vh;
  display: flex;
  background: $main-bg;
  overflow: hidden;
  overflow-y: scroll;
  font-family: 'Roboto', sans-serif;
  color: $gray-text;

  a {
    text-decoration: none !important;
  }

  .pointer {
    cursor: pointer;
  }

  .container-fluid {
    height: 100%;
    padding: 0;
  }
}

input:active,
input:hover,
input:focus {
  outline: 0;
  outline-offset: 0;
}

.d-none {
  display: none !important;
}

.scroll-bar__style {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    left: 40px;
  }

  &::-webkit-scrollbar:vertical {
    width: 12px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ced0d6;
    border-radius: 6px;
    border: 4px solid #f0f2f8;
  }

  &::-webkit-scrollbar-track {
    border-radius: 6px;
    background-color: #f0f2f8;
  }
}

.hover-blue-button {
  transition: all 0.3s;

  &:hover {
    background: #0d5378;
  }
}

.app::-webkit-scrollbar {
  width: 0;
}

div::-webkit-scrollbar {
  width: 7px;
  border-radius: 3px;
}

div::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 3px;
}

*:focus {
  outline-color: $main-blue !important;
}
