.cameras-box {
  display: flex;
  overflow-x: auto;
  padding: 0 0 15px 0;
  margin-bottom: 22px;

  &-item {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    min-width: 186px;
    max-height: 170px;
    min-height: 170px;
    margin: 0 22px 0 0;
    cursor: pointer;

    &:last-child {
      margin: 0;
    }

    &-title {
      text-align: center;
      display: block;
      max-width: 100%;
      margin: 0 0 10px 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .video-item-box {
      position: relative;
      flex: 1;
      min-height: 0;
      overflow: hidden;

      .stub {
        height: 100%;
        width: 100%;
        background: #e8e8e8;
        border-radius: 6px;
      }

      .video-item {
        max-width: 186px;
        height: auto;
        border: 2px solid #1f80b4;
        border-radius: 6px;
        min-height: 100%;
        min-width: 100%;
        object-fit: cover;
      }

      .video-item-duration {
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 5px 10px;
        background: #1f80b4;
        color: #fff;
        font-size: 14px;
        line-height: 24px;
        border-radius: 6px 0px;
      }
    }

    .video-item-box.active {
      .video-item {
        border: 2px solid #e85c5c;
      }

      .video-item-duration {
        background: #e85c5c;
      }
    }
  }
}
