.accounts-card-item {
  min-height: 173px;
  padding: 25px;
  border: 1px solid #8fbfda;
  box-sizing: border-box;
  border-radius: 6px;
  transition: all 0.3s ease;

  svg {
    path {
      transition: fill 0.3s ease;
    }
  }

  &:hover {
    background: #1f80b4;
    cursor: pointer;

    .accounts-card-item__country-name,
    .accounts-card-item__title,
    .accounts-card-item__name {
      color: #ffffff;
    }

    svg {
      path {
        fill: #ffffff;
      }
    }
  }

  &:nth-of-type(4n) {
    margin-right: 0;
  }

  &:nth-of-type(4n + 1) {
    margin-left: 0;
  }

  &__country {
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #8fbfda;

    .header {
      display: flex;
      align-items: center;
    }
  }

  &__icon {
    margin-right: 10px;
  }

  &__country-name {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 19px;
    color: #1f80b4;
    transition: all 0.3s ease;
  }

  &__label {
    margin-top: 15px;
    display: flex;
    align-items: flex-start;
  }

  &__icon {
    margin-right: 8px;
    margin-top: 4px;
    width: 15px;
    min-width: 15px;
  }

  &__title {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #1f80b4;
    transition: all 0.3s ease;
  }

  &__name {
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 19px;
    color: #1f80b4;
    transition: all 0.3s ease;
  }
}

.account-delete-button {
  background: none;
  border: none;
  outline: none;

  &-icon {
    path {
      fill: #ff3838;
    }
  }

  &:focus {
    outline: none;
  }
}
