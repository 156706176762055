$main-blue: #1F80B4;
$blue-light: #8FBFDA;
$main-bg: #F9FAFF;
$red: #E85C5C;
$red-light: #FADEDE;
$green: #54D771;
$green-light: #DDF7E3;
$gray-text: #A8A8A8;
$gray-line-1px: #E8E8E8;
$gray-big-line: #F0F2F8;
