.processes {
  height: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}

.topPanel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e2e2e2;
  margin-top: 20px;
  padding: 15px 0;
}

.viewDropdown {
  width: 200px;
}

.table {
  flex: 1;
}
