.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  background-color: #fff;
  border-radius: 3px;
  padding: 25px;
  box-shadow: 1px 2px 30px rgba(0, 0, 0, 0.15);
  z-index: 1000;
}

.gateSelect {
  width: 100px;
}

.applyButton {
  min-width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1f80b4;
  margin-right: 15px;
  border-radius: 50%;
  border: none;
  outline: none;
  transition: all 0.3s ease;

  svg {
    fill: #fff;
  }
}

.deleteButton {
  background: none;
  border: none;
  outline: none;

  &:focus {
    outline: none;
  }

  svg {
    fill: red;
  }
}
