@import '~theme/theme';

.camera-modal {
  width: 1000px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  font-weight: 300;

  & > * {
    margin-bottom: 20px;
  }

  .my-input {
    margin-bottom: 10px;
  }

  .attention {
    margin-top: 20px;
    margin-bottom: 0;
    padding: 9px 15px 9px 10px;
    border-radius: 6px;
    background: rgba(255, 56, 56, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__icon {
      margin-right: 15px;

      path {
        fill: #ff3838;
      }

      &_close {
        path {
          fill: #ff3838;
        }
      }
    }

    &__text {
      width: 100%;
      margin-right: 20px;
      margin-bottom: 0;
      font-size: 16px;
      line-height: 19px;
      color: #ff3838;
    }
  }

  .btn-submit {
    width: 100%;
    margin: 0;
    margin-top: 25px;
    padding: 9px 15px;
    font-weight: 500;
    font-size: 16px;
    color: #fff;
    background: #1f80b4;
    border-radius: 6px;
    border: none;
    outline: none;
  }
}
