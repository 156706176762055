@import '~theme/theme';

.root {
  position: relative;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: 1px solid #1f80b4;
  box-sizing: border-box;
  border-radius: 6px;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.buttonText {
  margin: 0 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #1f80b4;
}

.listContainer {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  min-width: 400px;
  z-index: 999;
  width: 100%;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #8fbfda;
  box-sizing: border-box;
  border-radius: 6px;
}

.searchInput {
  margin-bottom: 20px;
}

.itemsList {
  width: 100%;
  height: 250px;
  padding-right: 8px;
  overflow-y: auto;
  list-style: none;

}

.item {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  outline: none;

  &:last-child {
    margin-bottom: 0;
  }

  &_active {
    font-weight: bold;
  }
}

.itemLabel,
.itemCountry {
  font-size: 16px;
  line-height: 19px;
  color: #1f80b4;
}

.itemLabel {
  margin-right: 15px;
}

.itemCountry {
  text-align: right;
}
