.table-header-filter {
  width: 100%;
  display: flex;
  align-items: center;

  & > * {
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }

  .filter-group {
    display: flex;
    align-items: center;

    & > * {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .filter-tag {
    padding: 5px;
    display: flex;
    align-items: center;
    background: #f0f2f8;
    border-radius: 6px;

    .delete-filter-button {
      background: none;
      border: none;
      outline: none;

      &:focus {
        outline: none;
      }
    }

    & > * {
      padding: 0 5px;
    }

    .tag-key,
    .tag-value {
      font-size: 14px;
      line-height: 24px;
      color: #1f80b4;
    }

    .tag-key {
      font-weight: 500;
    }

    .tag-close {
      rect {
        fill: #8fbfda;
      }
    }

    .tag-icon {
      path {
        fill: #1f80b4;
      }
    }
  }

  .filter-search {
    display: flex;
    align-items: center;

    &__input {
      font-size: 16px;
      line-height: 19px;
      color: #a8a8a8;
      background: transparent;
      border: none;
      outline: none;
    }

    &__icon {
      margin-right: 15px;

      path {
        fill: #a8a8a8;
      }
    }
  }

  .add-filter {
    display: flex;
    align-items: center;
    position: relative;
    transition: all 0.3s;

    &-icon-wrap {
      margin-right: 10px;
      background: #f0f2f8;
      border-radius: 6px;
      padding: 3px;

      svg {
        path {
          fill: #1f80b4;
        }
      }
    }

    &-title {
      font-size: 14px;
      line-height: 16px;
      color: #a8a8a8;
      transition: color 0.2s ease;
    }

    &__box {
      display: flex;
      align-items: center;
      padding: 5px 15px 5px 12px;
      background: none;
      border: 1px dashed #cacaca;
      border-radius: 4px;
      outline: none;
      transition: border-color 0.2s ease;

      .plus-icon path {
        fill: #a8a8a8;
        transition: fill 0.2s ease;
      }

      &:hover {
        border-color: #1f80b4;

        .plus-icon path {
          fill: #1f80b4;
        }

        .add-filter-title {
          color: #1f80b4;
        }
      }
    }
  }
}
