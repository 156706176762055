.container {
  display: flex;
  margin-bottom: 15px;
}

.eventContainer {
  margin-top: 15px;
  padding-left: 30px;
}

.process,
.event {
  display: flex;
  align-items: center;
  flex: 1;
  height: 48px;
  padding: 0 10px;
  background: #fff;
  border-radius: 6px;
  color: #383838;
  outline: none;
}

.process {
  border: 1px solid #1f80b4;
  cursor: pointer;
}

.event {
  border: 1px solid #e8e8e8;
}

.critical {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  margin-left: 10px;
  border-radius: 6px;
  border: 1px solid #1f80b4;
  transition: all 0.2s ease;
  outline: none;
  user-select: none;
  cursor: pointer;

  &.checked {
    background: #1f80b4;
  }
}

.arrow {
  transform: rotate(0deg);
  transition: transform 0.2s ease;

  &.open {
    transform: rotate(-180deg);
  }
}
