.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.navigation {
  display: flex;
  align-items: center;
  max-height: 40px;
  padding-right: 25px;
  margin-right: 25px;
  border-right: 1px solid #e8e8e8;
}

.logo {
  fill: #1f80b4;
}

.prevPage {
  outline: none;
  background-color: #E9F3F8;
  color: #1f80b4;
  border: 1px solid #1f80b4;
  border-radius: 50%;
  margin: 0 10px 0 -10px;
  width: 25px;
  height: 25px;
  padding: 0;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: #1f80b4;
    color: #fff;

    .arrow {
      path {
        fill: #fff !important;
      }
    }
  }
}

.arrow {
  width: 100%;
  height: 100%;
  //margin-left: -10px;
  //margin-right: 10px;
  //background: #1f80b4;
  //border-radius: 50%;
  border-radius: inherit;

  path {
    fill: #1f80b4 !important;
  }
}
