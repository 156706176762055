@import "~theme/theme";

.login__bg {
  background: $main-blue;
  width: 100%;
  height: 100%;
}

.login-box__position {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login {
  background: #fff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  max-width: 320px;

  &-logo-box {
    display: flex;
    justify-content: center;
    align-content: center;
    background: #fff;
    box-shadow: 0 4px 30px rgba(103, 103, 103, 0.25);
    border-radius: 6px;
    padding: 15px 20px;
    max-width: 105px;
    margin: 0 auto;
    position: relative;
    top: -20px;

    svg {
      fill: $main-blue;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5px 0 0;

    &-heading {
      text-transform: uppercase;
      color: #000;
      font-weight: bold;
      margin: 0 0 16px;
    }

    &-form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 0 23px;

      &-input {
        padding: 10px 15px;
        margin: 0 28px 31px;
        min-width: 260px;
        border: 1px solid $gray-line-1px;
        outline: none;
        box-sizing: border-box;
        border-radius: 6px;

        &:first-child {
          margin-bottom: 20px;
        }

        &::placeholder {
          color: $gray-text
        }

        &:focus {
          border: 1px solid $main-blue;
        }
      }

      &-button {
        padding: 0;
        border: none;
        color: #fff;
        padding: 16px 19px 16px 14px;
        background: $main-blue;
        border-radius: 6px;

        svg {
          margin-right: 20px;
          fill: #fff;
        }
      }

      &-button:disabled {
        padding: 0;
        border: none;
        color: #fff;
        padding: 16px 19px 16px 14px;
        background: #919191;
        border-radius: 6px;

        svg {
          margin-right: 20px;
          fill: #fff;
        }
      }

      &-forgot {
        color: $main-blue;
        margin: 24px 0 0;
        padding-left: 3px;
        transition: all .3s;

        &:hover {
          color: #0d5378;
        }
      }

      &-error {

        color: $red;
        margin: 0px;
        margin-bottom: 10px;

      }
    }
  }
}