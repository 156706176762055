.turnaroundSwitch {
  display: flex;
  align-items: center;

  .switch {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .switchLeft {
    transform: rotate(-180deg);
  }

  .currentTurnaround {
    margin: 0 10px;
  }

  .timestamp {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;

    .value {
      color: #1f80b4;
    }
  }
}
