.timeline-container {
  position: absolute;
  left: 645px;
  top: 0;
  height: 100%;
  width: calc(100% - 645px - 82px);

  .timeline {
    z-index: 5;
  }
}

.airplane-detail-table {
  //padding-top: 15px;
  height: 60vh;

  .gridlines {
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 645px;
    top: 0;
    height: 100%;
    width: calc(100% - 645px - 82px);
    z-index: 1;

    .line {
      position: absolute;
      top: 0;
      height: 100%;
      width: 1px;
      background: #e8e8e8;
    }

    .dashed-line {
      width: 0;
      border-left: 2px dashed #e8e8e8;
      background: none;
    }
  }

  &-header,
  &-header-title,
  &-box-time {
    display: flex;
    justify-content: flex-start;
  }
  &-header-title,
  &-header-timeline {
    padding: 11px 0 11px 13px;
  }

  &-header-title {
    width: 645px;
    min-height: 46px;

    &.eventClass {
      max-width: 605px;
      color: #000;
    }

    &.event {
      max-width: 565px;
    }
  }

  .airplane-detail-table-header-item {
    display: flex;
    position: relative;
    align-items: center;
    &:first-child {
      flex-basis: 296px;
    }
    &:nth-child(2) {
      flex-basis: 120px;
    }
    &:nth-child(3) {
      flex-basis: 130px;
    }
    &:last-child {
      flex-basis: 150px;
    }
  }

  &-header-timeline {
    flex-grow: 1;
    display: flex;
    position: relative;
    .time-line-box.time-line-box__width {
      width: 450px;
    }
  }

  &-header-timeline.line {
    * {
      display: flex;
      justify-content: center;
    }
  }

  &-header-timeline.top {
    padding-left: 20px;
    justify-content: space-around;
    max-width: 481px;
    @media screen and (min-width: 1441px) {
      max-width: initial;
    }
    * {
      &:after {
        top: 46px;
        background: #ced0d6;
      }
    }
  }

  &-header-timeline.bottom {
    position: relative;
    padding-left: 0;
    justify-content: space-between;

    .airplane-detail-table-header-time {
      width: 1px;
      position: absolute;
    }
  }
}
