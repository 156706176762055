.container {
}

.buttonsGroup {
  display: flex;
  width: 100%;
  margin-top: 50px;
}

.confirm {
  margin-right: 15px;
}
