.container {
  margin-top: 40px;
}

.header {
  flex: 1;
  color: #383838;
}

.noAirline {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
