@import '~theme/theme';

.airport {
  svg {
    path {
      fill: #1f80b4;
    }

    circle {
      fill: #8fbfda;
    }
  }

  .accordion {
    margin-top: 40px;
    display: block;

    &-item {
      border-bottom: 1px solid #e8e8e8;
      transition: all 0.3s ease;

      &:hover > .accordion-item-close-container button {
        display: block;
      }

      &:hover > .accordion-item-close-container .attention {
        display: block;
      }

      &-close-container {
        width: 24px;

        button {
          background: none;
          border: none;
          outline: none;
          display: none;
        }
      }

      &-edit-field {
        padding: 3px 7px;
        margin-left: 10px;
        background: none;
        border-radius: 4px;
        color: $gray-text;
        border: 1px solid $gray-text;
        text-align: center;
        transition: all 0.3s ease;

        &__active {
          border: 1px solid $main-blue;
          color: $main-blue;
        }
      }

      &-title_active {
        font-weight: bold;
        color: #000;
      }

      &_active {
        border-bottom: 1px solid #1f80b4;
      }

      &__wrap {
        display: flex;
        align-items: center;
      }

      &__title {
        margin: 0 10px 0 0;
        font-size: 16px;
        line-height: 19px;
        color: #a8a8a8;
      }

      &__id {
        font-size: 16px;
        line-height: 19px;
        color: #a8a8a8;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        border: 1px solid #a8a8a8;
        box-sizing: border-box;
        border-radius: 4px;
      }

      &__edit {
        margin-left: auto;
        cursor: pointer;

        path {
          fill: #8fbfda;
        }
      }
    }

    &-inner-item {
      position: relative;
      border-bottom: 1px solid #e8e8e8;
      transition: all 0.3s ease;

      .accordion-item-close-container {
        position: absolute;
        right: 0;
        display: flex;
        width: 54px;

        .attention {
          display: none;
          position: relative;
          margin-right: 5px;

          svg {
            path {
              fill: #ffffff;
            }

            circle {
              fill: #ff3838;
            }
          }

          &__wrap {
            z-index: 20;
            position: absolute;
            top: 30px;
            right: 0;
            margin-bottom: 0;
            padding: 20px;
            min-width: 250px;
            background: #ffebeb;
            border: 1px solid #ff3838;
            box-sizing: border-box;
            border-radius: 6px;
            display: none;
            align-items: stretch;
            flex-direction: column;
            justify-content: space-between;
          }

          &:hover {
            .attention__wrap {
              display: flex;
            }
          }

          &__title {
            margin-bottom: 5px;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            text-transform: uppercase;
            color: #ff3838;
          }

          &__text {
            width: 100%;
            margin-bottom: 0;
            font-size: 14px;
            line-height: 24px;
            color: #ff3838;
          }
        }
      }

      &:hover > .accordion-item-close-container button,
      &:hover > .accordion-item-close-container .attention {
        display: block;
      }

      &__active {
        border-bottom: 1px solid $main-blue;
      }

      &-wrap {
        margin-top: 10px;
        padding: 0 0 0 35px;
        display: flex;
        flex-direction: column;
        align-items: stretch;
      }

      &__title {
        margin: 0 10px 0 0;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
      }

      &__id {
        font-size: 16px;
        line-height: 19px;
        color: #a8a8a8;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        border: 1px solid #a8a8a8;
        box-sizing: border-box;
        border-radius: 4px;
      }

      &-row {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        width: 100%;

        .action {
          &_empty {
            display: block;
            width: 36px;
            height: 57px !important;
          }
        }

        .col {
          &_border {
            display: flex;
            align-items: center;
            border-bottom: 1px solid transparent;
            padding: 10px 0;
            border-bottom: 1px solid #e8e8e8;

            .event {
              padding: 8px;
              color: #1f80b4;
              width: 150px;
              text-align: center;
              background: #f0f2f8;
              border-radius: 5px;
            }
          }

          &__text {
            max-width: 80%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 16px;
            line-height: 19px;
            color: #000000;
          }
        }
      }

      .col {
        display: flex;
        align-items: center;

        &__title {
          margin-bottom: 0;
          font-size: 14px;
          line-height: 16px;
          text-transform: uppercase;
          color: #a8a8a8;
        }
      }

      .action {
        position: relative;
        cursor: pointer;

        &__content {
          z-index: 10;
          padding: 12px;
          position: absolute;
          top: -13px;
          right: -13px;
          min-width: 200px;
          display: none;
          align-items: flex-start;
          justify-content: space-between;
          background: #ffffff;
          border: 1px solid #8fbfda;
          box-sizing: border-box;
          border-radius: 6px;

          &_active {
            display: flex;
          }
        }

        &__list {
          margin: 0;
          padding: 0;
          list-style: none;
        }

        &__list-item {
          display: flex;
          align-items: center;
        }
      }
    }

    &-add {
      display: flex;
      justify-content: flex-end;
      margin: 20px 0 40px;
      align-items: center;

      &-button {
        background: none;
        border: none;
        outline: none;

        &:hover {
          svg path {
            fill: #0d5378;
          }

          .accordion-add__title {
            color: #0d5378;
          }
        }
      }

      &-box {
        display: flex;
        align-items: center;
        background: none;
        border: none;
        outline: none;
        transition: all 0.4s;

        &:hover {
          svg path {
            fill: #0d5378;
          }

          .accordion-add__title {
            color: #0d5378;
          }
        }
      }

      &__title {
        font-size: 16px;
        line-height: 19px;
        color: #1f80b4;
      }

      &__icon {
        path {
          fill: #1f80b4;
        }
      }

      &-new {
        max-width: 450px;
        width: 100%;

        &__current {
          margin-bottom: 15px;
          padding-bottom: 15px;
          display: flex;
          align-items: center;
          font-size: 16px;
          line-height: 19px;
          color: #1f80b4;
          border-bottom: 1px solid #e8e8e8;

          &_check {
            padding-bottom: 0;
            font-weight: normal;
            color: #000000;
            border-bottom: none;
          }

          &-id {
            margin-left: 10px;
            font-size: 16px;
            line-height: 19px;
            color: #1f80b4;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25px;
            height: 25px;
            border: 1px solid #1f80b4;
            box-sizing: border-box;
            border-radius: 4px;
          }
        }

        &__gate {
          margin-bottom: 15px;
          display: flex;
          align-items: center;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          color: #000000;

          &-id {
            margin-left: 10px;
            font-size: 16px;
            line-height: 19px;
            color: #1f80b4;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25px;
            height: 25px;
            border: 1px solid #f0f2f8;
            box-sizing: border-box;
            background: #f0f2f8;
            border-radius: 4px;
          }
        }

        &__title {
          margin-top: 10px;
          margin-bottom: 20px;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          text-transform: uppercase;
          color: #a8a8a8;
        }

        &__list {
          margin-bottom: 25px;
          padding-bottom: 10px;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          max-height: 140px;
          height: 100%;
          border-bottom: 1px solid #e8e8e8;

          & > * {
            max-width: 150px;
            width: 100%;
            margin-bottom: 15px;
          }

          &_inner {
            padding-left: 60px;
          }

          &_check {
          }
        }

        &__next {
          display: flex;
          align-items: center;
          cursor: pointer;

          svg {
            margin-right: 10px;
          }

          span {
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            color: #1f80b4;
          }

          &:hover {
            svg path {
              fill: #0d5378;
            }

            span {
              color: #0d5378;
            }
          }
        }

        &__back {
          margin-right: 25px;
          display: flex;
          align-items: center;
          cursor: pointer;

          svg {
            margin-right: 10px;

            path {
              fill: #8fbfda;
            }
          }

          span {
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            color: #8fbfda;
          }

          &:hover {
            svg path {
              fill: #1f80b4;
            }

            span {
              color: #1f80b4;
            }
          }
        }

        .footer {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
