.header {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  padding-top: 0;
}

.codeSelect {
  flex-basis: 60%;
}

.snippet {
  width: 650px;

  & pre code {
    white-space: pre-wrap !important;
    word-break: break-all !important;
  }
}
