.container {
  min-width: 400px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid #e2e2e2;
  color: #000;

  svg {
    margin-right: 8px;
  }
}

.close {
  background: none;
  border: none;
  outline: none;

  &:focus {
    outline: none;
  }
}

.main {
  padding: 20px;
}

.applyButton {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.select {
  margin-bottom: 12px;
}

.itemsList {
  width: 100%;
  max-height: 200px;
  margin-top: 20px;
  padding-right: 8px;
  overflow-y: auto;
  list-style: none;
}

.item {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  outline: none;

  &:last-child {
    margin-bottom: 0;
  }

  &_active {
    font-weight: bold;
  }
}

.itemLabel,
.itemCountry {
  font-size: 16px;
  line-height: 19px;
  color: #1f80b4;
}

.itemLabel {
  margin-right: 15px;
}

.itemCountry {
  text-align: right;
}
