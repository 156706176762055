.apiCategory {
  color: #303030;
}

.container {
  margin-bottom: 20px;
}

.request {
  margin-bottom: 12px;
  cursor: pointer;
  outline: none;
}

.requestType {
  font-size: 11px;
}

.requestName {
  color: #303030;
  font-size: 14px;
  padding-left: 10px;
}
