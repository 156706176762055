.container {
  border: 1px solid #1f80b4;
  border-radius: 4px;
  margin-bottom: 30px;
}

.list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  column-gap: 20px;
  justify-content: space-between;
  margin-top: 40px;
}

.newProcessContainer {
  margin: 25px 0 15px;
}

.newProcessInner {
  display: flex;
  align-items: center;
}

.newProcessInput {
  display: flex;
  margin-right: 20px;

  .select {
    width: 200px;
    margin-right: 15px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
}

.inner {
  height: 400px;
  overflow-y: auto;
}

.eventList {
  padding: 15px 20px;
  margin-bottom: 15px;
}

.event {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.classSelect {
  min-width: 150px;
}

.title {
  color: #383838;
}
