.page-header {
  padding-bottom: 10px;
  border-bottom: 1px solid transparent;
}

.header-info {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-info-buttons {
  display: flex;
}

.filter {
  margin-left: 15px;
}

.camera {
  &-row {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    column-gap: 10px;
    justify-content: space-between;

    &_first {
      margin-top: 40px;
    }
  }

  &-add-card {
    min-height: 210px;
    background: #f9faff;
    border: 1px solid #8fbfda;
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    padding: 20px;
    margin-top: 10px;
    cursor: pointer;
    outline: none;

    .text {
      margin: 0;
      padding: 10px;
      max-width: 115px;
      width: 100%;
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      text-align: center;
      color: #aaaaaa;
    }
  }
}
