.container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  column-gap: 20px;
  row-gap: 20px;
  justify-content: space-between;
  margin-top: 40px;
}

.newStandardContainer {
  display: flex;
  align-items: center;

  .input {
    width: 200px;
    margin: 0 10px;
  }

  .label {
    display: inline-block;
    margin-bottom: 4px;
    font-size: 14px;
  }

  .createButton {
    margin-left: auto;
    align-self: flex-end;
  }
}
