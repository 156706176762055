.header {
  display: flex;
  align-items: center;
  padding-right: 10px;
  margin-left: -10px;
  outline: none;
  cursor: pointer;
}

.arrow {
  transform: rotate(0deg);
  transform-origin: center center;
  transition: transform 0.2s ease;
  fill: #1f80b4;

  &__open {
    transform: rotate(90deg);
  }
}

.content {
  padding-left: 20px;
}
